<script>
export default {
  data() {
    return {
      routeChecked: false,
    }
  },
  mounted() {
    this.$on('AppRouteMixin.user.fetched', this.checkRouteAccess)
  },
  destroyed() {
    this.$off('AppRouteMixin.user.fetched', this.checkRouteAccess)
  },
  methods: {
    checkRouteAccess() {
      if (
        !this.routeChecked &&
        this.$store.state.user.userData &&
        Object.keys(this.$store.state.user.userData).length > 0
      ) {
        this.routeChecked = true
        this.$router.push(this.localePath({ name: 'index' }))
      }
    },
  },
}
</script>
