<template>
  <PageLogin/>
</template>

<script>
import AppRouteMixin from '~/mixins/AppRouteMixin'
import LoggedOutUserRestrictedRouteMixin from '~/mixins/LoggedOutUserRestrictedRouteMixin'
import PageLogin from '@theme/components/user/PageLogin'

const PAGE = {
  name: 'RouteLogin',
  nuxtI18n: {
    paths: {
      cs: '/prihlasit',
      de: '/einloggen',
      en: '/login',
      sk: '/prihlasit',
    },
  },
  middleware({ store }) {
    store.commit('locale/SET_ALTERNATIVE_PATHS', PAGE.nuxtI18n.paths)
  },
  components: {
    PageLogin,
  },
  mixins: [AppRouteMixin, LoggedOutUserRestrictedRouteMixin],
  async asyncData({ store }) {
    store.commit('locale/SET_CANONICALS')
  },
}
export default PAGE
</script>
