<script>
import Component from '~/sites/shop/pages/login'
export default {
  extends: Component,
  nuxtI18n: {
    paths: {
      cs: '/prihlasit',
      en: '/login',
      sk: '/prihlasit',
    },
  },
}
</script>
