import AppButton from '@theme/components/atom/AppButton'
import AppInput from '@theme/components/atom/AppInput'
import AppTitle from '@theme/components/atom/AppTitle'
import Breadcrumbs from '@theme/components/molecule/Breadcrumbs'
import ContactBox from '@theme/components/shop/ContactBox'
import LayoutBase from '@theme/components/shop/LayoutBase'
import LoadingMixin from '~/mixins/LoadingMixin'
import LoginForm from '@theme/components/user/LoginForm'
import PageLoginBelowLoginFormHook from '@theme/components/user/PageLoginBelowLoginFormHook'
import ProvideValidator from '~/app/ProvideValidator'
import RequiredSign from '@theme/components/atom/RequiredSign'
import SeoMixin from '~/mixins/SeoMixin'

export default {
  components: {
    AppButton,
    AppInput,
    AppTitle,
    Breadcrumbs,
    ContactBox,
    LayoutBase,
    LoginForm,
    PageLoginBelowLoginFormHook,
    RequiredSign,
  },
  mixins: [LoadingMixin, ProvideValidator, SeoMixin],
  head() {
    return this.getSeoHead({ yamlKey: 'PageLogin', noindex: true, nofollow: false })
  },
  data() {
    return {
      breadcrumbs: [
        {
          title: this.$t('PageLogin.breadcrumbs.index'),
          path: this.localePath({ name: 'login' }),
        },
      ],
      redirectPath: this.$route.query.path
        ? this.$route.query.path
        : this.localePath({ name: this.$contentSettings.shopHomePagePath }),
    }
  },
  destroyed() {
    this.stopLoading()
  },
}
